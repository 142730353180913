body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accessibility
{position:absolute;
left:-10000px;
top:auto;
width:1px;
height:1px;
overflow:hidden;}

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f5f5f5;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfecf8;
  border-radius: 10px;
}
a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}
a.skip-main:focus {
    left: auto;
    top: auto;
    width: 20%;
    margin-top: 20px;
    height: auto;
    overflow: auto;
    margin: 0 35%;
    padding: 5px;
    font-size: 20px;
    text-align: center;
    z-index: 999;
}